import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/SEO'
import { Container } from '../styles/PageLayout'
import { Headline, ParagraphM } from '../styles/Typography'

const Success = () => (
  <>
    <SEO title="Success" />
    <Container column mobileColumn minHeight="75vh" gap="1.5em">
      <Headline>Thanks, we'll get back to you!</Headline>
      <Link to="/">
        <ParagraphM>← go back home</ParagraphM>
      </Link>
    </Container>
  </>
)

export default Success
